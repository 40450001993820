<template>
  <section>
    <div class="headerSty">
      <img
        style="float: left; margin-left: 10px; margin-top: 10px"
        height="45px"
        width="25px"
        src="@/assets/img/eSignsPenIcon.png"
      />
      <span
        style="
          border-left: 2px solid #d3d2d3;
          height: 40px;
          display: inline-block;
          margin-top: 10px;
          float: left;
          margin-left: 20px;
        "
      ></span>
      <span
        style="
          color: black;
          font-size: 16px;
          font-weight: 500;
          margin-left: 10px;
          margin-top: 1%;
          display: inline-block; /* Add this to apply vertical margins */
        "
      >
        AI ChatMate
      </span>
      <el-button
        type="danger"
        style="float: right; margin-top: 10px; margin-right: 10px"
        @click="goToDocuments()"
        >Continue to sign</el-button
      >
    </div>
    <el-card
      shadow="never"
      style="margin-top: 1px; position: relative; background-color: #ffffff"
    >
      <div style="height: 85vh; background: #ffffff">
        <split-view
          direction="horizontal"
          :min-percent="20"
          :default-percent="50"
          :resizer-width="50"
          :max-percent="80"
          class="split-view"
          a-min="350px"
          a-max="1000px"
        >
          <template #A>
            <div class="content">
              <vue-pdf-app
                class="pdfClass"
                :config="config"
                :pdf="pdfData"
                style="width: 100%; height: 100%"
              ></vue-pdf-app>
            </div>
          </template>
          <template #B>
            <div style="margin-bottom: -22px; background: #ffffff">
              <div>
                <div
                  style="
                    width: calc(100% - 20px);

                    margin-left: 10px;

                    display: flex;

                    align-items: center;

                    justify-content: space-between;

                    padding: 0 10px;

                    font-size: 16px;

                    background: #ffffff;

                    box-shadow: 5px;
                  "
                >
                  <span @click="goToDocuments()"
                    ><i @click="goBack()" class="el-icon-close"></i>AI
                    ChatMate</span
                  >

                  <div style="display: flex; align-items: center">
                    <img
                      @click="getHistory()"
                      style="cursor: pointer"
                      src="@/assets/img/History.svg"
                    />

                    <!-- <img class="ml-1" src="@/assets/img/Copy.svg" /> -->

                    <!-- <img
                      @click="shareChat"
                      style="cursor: pointer"
                      class="ml-1"
                      src="@/assets/img/Share.svg"
                    />

                    <img class="ml-1" src="@/assets/img/Download.svg" /> -->

                    <img
                      style="cursor: pointer"
                      @click="openNewSession"
                      class="ml-1"
                      src="@/assets/img/New Chat.svg"
                    />
                  </div>
                </div>

                <el-card v-if="sharingChat" class="box-card">
                  <span
                    >Share your chat with others

                    <i
                      @click="sharingChat = false"
                      style="float: right"
                      class="el-icon-close"
                    ></i>
                  </span>

                  <el-divider />
                </el-card>
              </div>

              <hr />

              <el-card
                style="
                  margin-top: 1px;
                  position: relative;
                  background-color: #ffffff;
                "
                ref="scrollContainer"
                class="cardSty"
              >
                <div
                  v-if="
                    (responseHistory && !responseHistory.length) || newSession
                  "
                  style="
                    margin-top: 5%;

                    margin-left: 44%;

                    border-color: var(--primary-color) !important;

                    padding: 8px 18px;

                    width: 50px;

                    height: 50px;

                    margin-bottom: 10px;
                  "
                >
                  <img src="@/assets/img/AI ChatMate.svg" />
                </div>
                <template
                  v-if="
                    (responseHistory && !responseHistory.length) || newSession
                  "
                >
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      justify-content: center;
                      text-align: center;
                    "
                  >
                    <span
                      style="
                        font-size: 20px;
                        font-weight: 500;
                        font-family: Inter;
                      "
                      >Hi, I'm your AI ChatMate</span
                    >
                    <br />
                    <span
                      style="
                        font-size: 14px;
                        font-weight: 400;
                        color: #606266;
                        font-family: Inter;
                      "
                      >I'm here to help with any questions or information about
                      <br />your uploaded document.</span
                    >
                  </div>
                  <div class="ai-questions">
                    <span class="questions-heading">
                      Choose from these suggestions, or ask your own question:
                    </span>

                    <div
                      v-for="(item, index) in initialQuestionSet"
                      :key="index"
                      class="each-question"
                    >
                      <span @click="handleSelectedQuestion(item)">{{
                        item
                      }}</span>

                      <i
                        @click="handleSelectedQuestion(item)"
                        class="el-icon-right question-icon"
                      ></i>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div
                    v-for="(historyItem, index) in responseHistory"
                    :key="index"
                  >
                    <div class="d-flex">
                      <img
                        width="25px"
                        height="25px"
                        src="@/assets/img/ContactChat.svg"
                        style="margin-left: -10px"
                      />
                      <p class="question-heading">
                        {{ historyItem.question }}
                      </p>
                    </div>
                    <div class="qa-container">
                      <img
                        width="25px"
                        height="25px"
                        src="@/assets/img/AI ChatMate.svg"
                      />
                      <div class="ai-questions-answers ml-2">
                        <!-- Display the answer -->

                        <p class="answer-heading">
                          {{ historyItem.answer }}
                        </p>

                        <!-- Loop through the sub-questions within each response -->
                        <el-collapse @change="autoScroll">
                          <el-collapse-item title="Related Questions">
                            <div
                              v-for="(
                                itemQa, subIndex
                              ) in historyItem.questions"
                              :key="subIndex"
                              class="each-sub-question"
                            >
                              <div class="question-wrapper ml-1">
                                <span @click="handleSelectedQuestion(itemQa)">
                                  {{ itemQa }}
                                </span>
                              </div>
                              <!-- <i class="el-icon-right"></i> -->
                            </div>
                          </el-collapse-item>
                        </el-collapse>
                      </div>
                    </div>
                  </div>
                </template>
                <div class="loader-container" v-if="aILoading === true">
                  <div class="loader">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </el-card>
            </div>
            <template>
              <el-row class="bottom-styling">
                <!-- <el-col :span="6" class="summary-col mb-1">
                  <span class="label-text"
                    ><img
                      src="@/assets/img/Summarize.svg"
                      style="margin-right: 4px"
                    />
                    Summarize</span
                  >
                </el-col>
                <el-col :span="6">
                  <span class="label-text"
                    ><img
                      src="@/assets/img/Highlight.svg"
                      style="margin-right: 4px"
                    />
                    Highlight</span
                  >
                </el-col> -->
                <el-col :span="24" class="input-col">
                  <!-- <el-input
                    class="custom-textarea"
                    v-model="currentQuestion"
                    placeholder="Ask me anything about your document"
                  ></el-input> -->
                  <div
                    class="text-box"
                    contenteditable="true"
                    id="editableDiv"
                    :disable="checkIfFetchingData"
                    @keyup.enter="sendAIMessage"
                    @keypress.enter.prevent
                  ></div>

                  <div tabindex="0" class="send-icon-wrapper">
                    <img
                      @click="sendAIMessage"
                      class="send-icon"
                      src="@/assets/img/Send.svg"
                      alt="Send Icon"
                    />
                  </div>
                </el-col>
              </el-row>
            </template>
            <el-drawer
              v-loading="aILoading"
              :element-loading-text="loadingText ? loadingText : 'Loading...'"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              size="25%"
              :visible.sync="isAiChatHistory"
              :with-header="false"
            >
              <div class="mt-1"></div>
              <span
                class="mr-1 ml-1"
                style="font-weight: 500; font-size: 16px; margin-bottom: -30px"
                >History</span
              >
              <i
                @click="isAiChatHistory = false"
                style="
                  margin-left: 70%;
                  cursor: pointer;
                  font-size: 16px;
                  font-weight: 600;
                "
                class="el-icon-close"
              ></i>
              <el-input
                class="ml-1 mt-1"
                style="width: 350px"
                placeholder="Search"
              ></el-input>
              <el-divider />
              <template>
                <el-row
                  v-for="(questions, id) in sessions[0]"
                  :key="id"
                  class="session-card"
                >
                  <el-col :span="24">
                    <el-card shadow="hover" class="box-card">
                      <div v-for="(item, index) in questions" :key="index">
                        <p
                          v-if="item.length > 0"
                          @click="openPreviousChat(item, index)"
                          style="cursor: pointer"
                        >
                          {{ item[0].question }}
                          <el-button
                            type="plain"
                            size="mini"
                            class="delete-icon el-icon-delete"
                            circle
                            @click="deleteSession(id)"
                          >
                          </el-button>
                        </p>
                        <hr />
                      </div>
                    </el-card>
                  </el-col>
                </el-row>
              </template>
            </el-drawer>
          </template>
        </split-view>
      </div>
    </el-card>
  </section>
</template>

<script src="https://unpkg.com/vue"></script>
<script src="https://unpkg.com/vue-pdf-app@2.0.0"></script>
<script>
import SplitView from "vue-split-view/dist/vue2";
import "vue-pdf-app/dist/icons/main.css";
import VuePdfApp from "vue-pdf-app";
import { bus } from "../../../main";
import { mapGetters } from "vuex";
import axios from "@/config/axios";
export default {
  props: {
    selectedAttachmentFile: {
      type: String,
      required: true,
    },
    routeUrl: {
      type: Object,
      required: true,
    },
    aiData: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      sessions: [],
      uniqueId: "",
      responseHistory: [],
      aILoading: false,
      LoadingText: "",
      config: {
        sidebar: {
          viewThumbnail: true,
          viewOutline: false,
          viewAttachments: false,
        },
        errorWrapper: true,
        secondaryToolbar: {
          secondaryPresentationMode: false,
          secondaryOpenFile: false,
          secondaryPrint: false,
          secondaryDownload: true,
          secondaryViewBookmark: false,
          firstPage: true,
          lastPage: true,
          pageRotateCw: true,
          pageRotateCcw: true,
          cursorSelectTool: false,
          cursorHandTool: false,
          scrollVertical: true,
          scrollHorizontal: true,
          scrollWrapped: false,
          spreadNone: false,
          spreadOdd: false,
          spreadEven: false,
          documentProperties: false,
        },
        toolbar: {
          toolbarViewerMiddle: {
            zoomOut: true,
            zoomIn: true,
            scaleSelectContainer: false,
          },
          toolbarViewerRight: {
            presentationMode: false,
            openFile: false,
            print: false,
            download: false,
            viewBookmark: false,
          },
          toolbarViewerLeft: {
            previous: true,
            findbar: false,
            next: true,
            pageNumber: true,
          },
        },
      },
      qAndAResponse: {
        questions: [],
        answer: "",
      },
      isAiChatHistory: false,
      chatHistory: [
        {
          name: "Name 1",
        },
        {
          name: "Name 21",
        },
        {
          name: "Name 3",
        },
      ],
      initialQuestionSet: this.aiData ? this.aiData : this.localAiData,
      routingUrl: this.routeUrl ? this.routeUrl : this.localRouteUrl,
      pdfData: this.selectedAttachmentFile
        ? this.selectedAttachmentFile
        : this.localSelectedAttachmentFile,
      sharingChat: false,
      newSession: false,
      localSelectedAttachmentFile: null,
      localRouteUrl: "",
      localAiData: [],
    };
  },
  components: {
    // VueSplitter,
    // VueSplitterH,
    SplitView,
    VuePdfApp,
  },
  computed: {
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getInitialUrl",
      "getUserSignature",
      "getDefaultDateFormat",
      "getActiveWorkspace",
      "getUserType",
    ]),
  },
  mounted() {
    this.getUrlsSavingData =
      this.$store.getters["aiAssistant/getUrlsSavingData"];
    this.localSelectedAttachmentFile = localStorage.getItem(
      "selectedAttachmentFile"
    );
    this.localRouteUrl = JSON.parse(localStorage.getItem("routeUrl"));
    this.localAiData = JSON.parse(localStorage.getItem("aiData"));

    // Check and assign initial values from either the props, Vuex, or localStorage
    this.initialQuestionSet = this.aiData
      ? this.aiData
      : this.localAiData
      ? this.localAiData
      : this.getUrlsSavingData?.aiData || localAiData;

    this.routingUrl = this.routeUrl
      ? this.routeUrl
      : this.localRouteUrl
      ? this.localRouteUrl
      : this.getUrlsSavingData?.routeUrl || localRouteUrl;

    this.pdfData = this.selectedAttachmentFile
      ? this.selectedAttachmentFile
      : this.localSelectedAttachmentFile
      ? this.localSelectedAttachmentFile
      : this.getUrlsSavingData?.selectedAttachmentFile ||
        localSelectedAttachmentFile;
  },
  methods: {
    openPreviousChat(data, index) {
      console.log(index);
      this.responseHistory = data;
      this.uniqueId = index;
      this.isAiChatHistory = false;
    },
    async getHistory() {
      this.aILoading = true;
      this.loadingText = "Fetching History...";
      this.sessions = [];
      this.isAiChatHistory = true;
      let configId = this.routingUrl?.params?.configurable_document_id;
      let response = await axios.post(
        "https://ai.esigns.io/data_ingestion/get_chat",
        {
          admin_ids: [configId],
        }
      );
      this.sessions = response.data;
      this.aILoading = false;
      console.log("p--", this.sessions[0]);
    },
    openNewSession() {
      this.newSession = true;
      this.uniqueId = "";
    },
    checkIfFetchingData() {
      if (this.aILoading) {
        return true;
      } else {
        return false;
      }
    },
    autoScroll() {
      this.$nextTick(() => {
        const scrollContainer =
          this.$refs.scrollContainer.$el || this.$refs.scrollContainer;
        if (scrollContainer) {
          scrollContainer.scrollTop = scrollContainer.scrollHeight;
        }
      });
    },
    sendAIMessage() {
      const text = document.getElementById("editableDiv").innerText;
      this.getText(text);
      document.getElementById("editableDiv").innerText = "";
    },
    getText() {
      var editableDiv = document.getElementById("editableDiv");
      var text = editableDiv.innerText || editableDiv.textContent;
      this.currentQuestion = text;
      this.autoScroll();
      this.handleSelectedQuestion(this.currentQuestion);
      editableDiv.innerText = "";
    },
    async handleSelectedQuestion(item) {
      try {
        if (this.newSession === true) {
          this.responseHistory = [];
        }
        if (this.uniqueId === "") {
          this.newSession = false;
          this.uniqueId = Math.floor(Math.random() * 100000).toString();
        }
        console.log("this.routingUrl", this.routingUrl);
        let configId = this.routingUrl?.params?.configurable_document_id;
        console.log(configId);
        this.autoScroll();
        this.aILoading = true;
        let response = await axios.post("https://ai.esigns.io/chatbot/", {
          question: item,
          unique_id: this.uniqueId,
          admin_id: configId,
        });
        this.qAndAResponse = response?.data;
        const questionResponse = {
          question: item,
          answer: response.data.answer,
          questions: response.data.questions,
        };
        this.responseHistory.push(questionResponse);
        this.aILoading = false;
        console.log(this.responseHistory, "-0");
      } catch (err) {
        console.log(err);
      }
    },
    goBack() {
      console.log(this.routingUrl);
      this.$router.go(-1);
    },
    shareChat() {
      this.sharingChat = true;
    },

    goToDocuments() {
      console.log("this.getUrlsSavingData", this.localRouteUrl, this.routeUrl);
      this.routeUrl = this.routeUrl ? this.routeUrl : this.localRouteUrl;
      if (this.routeUrl && this.routeUrl.params) {
        this.$router.push({
          path: this.routeUrl.path,
          query: this.routeUrl.query,
        });
      } else {
        console.error("routeUrl or params is undefined");
      }
    },
  },
  watch: {
    responseHistory() {
      this.autoScroll();
    },
  },
};
</script>

<style scoped>
@import "../../../assets/styles.css";

.split-view {
  height: 100%;
}

.split-view [slot="A"] {
  min-width: 200px;
  max-width: 900px;
  /* Minimum width for Slot A */
}

.split-view [slot="B"] {
  max-width: 600px;
  /* Maximum width for Slot B */
}

.split-view .splitter {
  background: #fff0f7;
  /* Replace with your desired color */
  width: 30px;
  cursor: col-resize;
}

.content {
  display: flex;
  align-items: center;
  background: #ffffff;
  justify-content: center;
  height: 100%;
  /* Example background color for content */
}

.ai-questions-answers {
  background-color: #ecf8ff;
  width: 50%;
  max-width: 550px;
  height: auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* margin: 20px auto; */
}
.ai-questions {
  background-color: #ecf8ff;
  width: 100%;
  max-width: 550px;
  height: auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
}
.questions-heading {
  display: block;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.each-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 12px 16px;
  margin-bottom: 10px;
  border-radius: 6px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease,
    background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.each-question:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: scale(1.05);
}

.question-icon {
  color: #409eff;
  font-size: 16px;
}

.ai-questions .each-question span {
  font-size: 14px;
  color: #333;
  font-weight: 500;
}

/* .AiQuestions {
  background: #ecf8ff;
  width: 539px;
  height: 200px;
  gap: 10px;
  border-radius: 0px 6px 6px 6px;
  opacity: 0px;
  margin-left: 46px;
  line-height: 19.6px;
}
.eachQstn {
  width: 492px;
  top: 144px;
  left: 16px;
  padding: 8.69px 10.69px 8.69px 10px;
  opacity: 0px;
  height: 20px;
  background: #ffffff;
  margin-left: 8px;
} */

.bottomSty {
  background: #f8f8f8;
  width: 800px;
  height: 124px;
  opacity: 0px;
  margin-left: 30px;
  margin-top: 80px;
}

.cSty {
  margin-left: 10px;
  margin-top: 10px;
}

.pdf-toolbar {
  display: none;
  /* Example to hide default toolbar */
}

.pdf-app #toolbarContainer {
  color: black;
}

.pdf-app #toolbarViewerLeft {
  color: #000000;
}

.box-card {
  width: 350px;
  float: right;
  height: 625px;
}

.pdf-app .toolbarButton {
  background-color: var(--pdf-toolbar-color);
  color: var(--pdf-toolbar-font-color);
}

.pdf-app .toolbarButton:hover {
  color: var(--pdf-button-hover-font-color);
}

.pdf-app .toolbarButton.toggled {
  background-color: var(--pdf-button-toggled-color);
}

.bottom-styling {
  /* position: absolute; */
  bottom: 0;
  align-items: flex-start;
  left: 0;
  right: 0;
  border-top: 1px solid #ddd;
  box-sizing: border-box;
  padding: 15px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  background-color: #f8f8f8;
  margin-top: 50px;
}

.el-card {
  border-radius: 4px;
  border: 1px solid #ebeef5;
  background-color: #fff;
  overflow: hidden;
  color: #303133;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  box-shadow: 0 0 10px 2px rgba(27, 72, 126, 0.1294117647) !important;
  border-radius: 10px;
  overflow-y: auto;
  max-height: 90vh;
  scrollbar-width: thin;
}
.summary-col,
.highlight-col,
.input-col {
  display: flex;
  align-items: center;
}

.label-text {
  display: flex;
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

.custom-textarea {
  width: 100%;
  max-width: 600px;
  border: 1px solid #cccccc;
  border-radius: 6px;
  padding: 10px;
  font-size: 14px;
  line-height: 1.5;
  background-color: #ffffff;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.custom-textarea:hover,
.custom-textarea:focus {
  border-color: #409eff;
  box-shadow: 0 0 5px rgba(64, 158, 255, 0.3);
}
.response-container {
  width: 300px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.answer {
  font-size: 14px;
  margin-bottom: 20px;
  color: #333;
}
.question-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: box-shadow 0.3s ease, background-color 0.3s ease;
}
.question-container:hover {
  background-color: #f5f5f5;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* Icon styling for right arrow (optional, depending on usage) */
.question-icon {
  font-size: 12px;
  color: #606266;
}
.qa-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  margin-left: -35px;
}
.ai-questions-answers {
  flex: 2;
  max-width: 100%;
  padding-left: 20px;
  border-left: 3px solid var(--primary-color);
}
.question-heading {
  background-color: var(--primary-color);
  color: white;
  padding: 10px;
  border-radius: 8px;
  max-width: 80%;
  margin-left: 10px;
  margin-top: -10px;
  margin-bottom: -5px;
}

.answer-heading {
  margin-bottom: 20px;
  font-size: 14px;
}

.question-wrapper {
  display: inline-block;
  background-color: white;
  transition: all 0.3s ease-in-out;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.question-wrapper:hover {
  transform: scale(1.05);
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Add some gap between sub-questions */
.each-sub-question {
  margin-top: 5px;
  cursor: pointer;
}
.no-border .el-input__inner {
  border: none !important;
  box-shadow: none !important;
}
.cardSty {
  border-radius: 4px;
  border: 1px solid #ebeef5;
  background-color: #fff;
  /* overflow: hidden; */
  color: #303133;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  box-shadow: 0 0 10px 2px rgba(27, 72, 126, 0.1294117647) !important;
  border-radius: 10px;
  overflow-y: auto;
  scrollbar-width: thin;
  min-height: 60vh;
  max-height: 65vh;
}
.bottom-card {
  padding: 10px;
}
.text-box:focus {
  border: 1px solid var(--primary-color);
}
.text-box {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  overflow: hidden;
  min-height: 40px;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.5;
}

.text-box img {
  cursor: pointer;
  margin-right: 10px;
  vertical-align: middle;
}

.mic-icon {
  margin-right: auto; /* Push the microphone icon to the far right */
  margin-right: 5px;
  margin-left: 2px;
  cursor: pointer;
}
.send-icon {
  cursor: pointer;
  margin-left: 8px;
}
.text-box img:last-child {
  display: none; /* Hides the empty image element */
}
</style>
<style>
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  display: flex;
  justify-content: space-between;
  width: 80px;
}

.loader div {
  width: 12px;
  height: 12px;
  background-color: var(--primary-color);
  border-radius: 50%;
  animation: grow-shrink 1.5s infinite;
}

.loader div:nth-child(1) {
  animation-delay: 0s;
}

.loader div:nth-child(2) {
  animation-delay: 0.3s;
}

.loader div:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes grow-shrink {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
.headerSty {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  z-index: 1000;
  background: #fff;
  box-shadow: 0 0 10px 2px rgba(27, 72, 126, 0.1294117647) !important;
}
</style>
<style scoped>
.session-card {
  margin-bottom: 20px;
}

.delete-icon {
  float: right;
  cursor: pointer;
}
/* .delete-icon:hover {
  background-color: #ffedf3;
} */
.question,
.answer {
  padding-left: 20px;
}

.el-card {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  margin-right: 15px;
}

.el-collapse-item__header {
  font-weight: bold;
}
</style>
